import {
  EventLogRequest,
  LinkConfigRequest,
  PlaidLinkConfigResponse,
  PlaidLinkError,
  PlaidLinkExit,
  UpdateModeLinkConfigRequest
} from './types';

export class AcpPlaidPluginClient {
  constructor(private webapiResource: nsWebclient.WebapiResourceService) {
    'ngInject';
  }

  getPlaidLinkConfig = this.webapiResource<LinkConfigRequest, PlaidLinkConfigResponse>({
    method: 'GET',
    path: '/v2/ach_transfer/link_config?redirect_uri=:redirectUri'
  });

  getPlaidUpdateModeLinkConfig = this.webapiResource<
    UpdateModeLinkConfigRequest,
    PlaidLinkConfigResponse
  >({
    method: 'GET',
    path: '/v2/ach_transfer/link_config?bank_link_id=:bankLinkId&redirect_uri=:redirectUri'
  });

  logBankLinkExit = this.webapiResource<EventLogRequest<PlaidLinkExit>, void>({
    method: 'POST',
    path: '/v2/event/log',
    format: 'json'
  });

  logBankLinkError = this.webapiResource<EventLogRequest<PlaidLinkError>, void>(
    {
      method: 'POST',
      path: '/v2/event/log',
      format: 'json'
    }
  );
}
