import {
  PlaidEventHandlers,
  PlaidLinkConfig,
  PlaidLinkConfigResponse,
  PlaidLinkError,
  PlaidLinkExit,
  PlaidPluginSuccess
} from './types';
import { AcpDeviceServiceProvider } from 'core';
import { AcpPlaidPluginClient } from './acp-plaid-plugin-client';
import {
  mobilePlaidError,
  mobilePlaidSuccess
} from './acp-plaid-plugin-mobile-sdk';

declare const Plaid: any;

export class AcpPlaidPlugin {
  private plaidInstance: any = null;
  private readonly OAUTH_SUCCESS = 'oauth_success';
  private readonly LINK_TOKEN = 'linkToken';
  private readonly REAUTH_BANK_LINK_ID = 'reauthBankLinkId';
  plaidLoading = this.nsSignal.create<boolean>();
  private linkConfig: PlaidLinkConfig = null;

  constructor(
    private acpPlaidPluginClient: AcpPlaidPluginClient,
    private $location: ng.ILocationService,
    private nsSignal: nsUtils.NsSignalService,
    private $window: ng.IWindowService,
    private webapiResource: nsWebclient.WebapiResourceService,
    private nsStorage: any,
    private acpDeviceService: AcpDeviceServiceProvider
  ) {
    'ngInject';
  }

  async getRedirectUri(): Promise<string> {
    return this.isMobilePlaidEligible() && this.acpDeviceService.isAndroid()
      ? await this.$window.cordova.getAppVersion.getPackageName()
      : this.$location.absUrl().split('?')?.[0];
  }

  setOAuthSuccess() {
    this.nsStorage.session(this.OAUTH_SUCCESS, true);
  }

  getOAuthSuccess() {
    return this.nsStorage.session(this.OAUTH_SUCCESS);
  }

  removeOAuthSuccess() {
    this.nsStorage.removeSessionItem(this.OAUTH_SUCCESS);
  }

  private getLinkToken(): string {
    return this.nsStorage.session(this.LINK_TOKEN);
  }

  private setLinkToken(val: string): void {
    this.nsStorage.session(this.LINK_TOKEN, val);
  }

  private removeLinkToken(): void {
    this.nsStorage.removeSessionItem(this.LINK_TOKEN);
  }

  getReauthBankLinkId(): string {
    return this.nsStorage.session(this.REAUTH_BANK_LINK_ID);
  }

  private setReauthBankLinkId(val: string): void {
    this.nsStorage.session(this.REAUTH_BANK_LINK_ID, val);
  }

  removeReauthBankLinkId(): void {
    this.nsStorage.removeSessionItem(this.REAUTH_BANK_LINK_ID);
  }

  isOAuthUrl(): boolean {
    return this.$location.absUrl().includes('oauth_state_id');
  }

  hasOAuth(): boolean {
    return this.isOAuthUrl() && !!this.getLinkToken();
  }

  setPlaidLoading(flag: boolean) {
    this.plaidLoading.emit(flag);
  }

  isMobilePlaidEligible() {
    return !!this.$window.plugins?.PlaidPlugin;
  }

  private extendEventHandler(
    eventHandlers: PlaidEventHandlers
  ): Required<PlaidEventHandlers> {
    return {
      onSuccess: (public_key: any, metadata: any) => {
        eventHandlers.onSuccess(public_key, metadata);
        if (!this.isMobilePlaidEligible()) {
          this.removeLinkToken();
        }
      },
      onExit: (error: PlaidLinkError, metadata: any) => {
        this.logLinkExit(metadata as PlaidLinkExit);
        if (!this.isMobilePlaidEligible()) {
          this.removeLinkToken();
          this.removeReauthBankLinkId();
          this.plaidInstance.destroy();
        }
        if (eventHandlers.onExit) {
          eventHandlers.onExit(error, metadata);
        }
      },
      onEvent: (eventName: string, metadata: any) => {
        if (eventName === 'ERROR') {
          this.logLinkError(metadata as PlaidLinkError);
        }

        if (eventHandlers.onEvent) {
          eventHandlers.onEvent(eventName, metadata);
        }
      }
    };
  }

  pushHistoryStates(pageNavs: string[]): void {
    // Push new browser history after successfully link with bank
    pageNavs.forEach((page) =>
      this.$window.history.pushState(null, '', `/account/${page}`)
    );
  }

  private logLinkExit(payload: PlaidLinkExit): void {
    try {
      this.acpPlaidPluginClient.logBankLinkExit({
        events: [
          {
            payload: payload,
            event_type: 'PLAID_LINK_EXIT',
            timestamp: new Date().toISOString()
          }
        ]
      });
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('ACP Plaid: Could not log bank link exit event', ex);
    }
  }

  private logLinkError(error: PlaidLinkError): void {
    try {
      this.acpPlaidPluginClient.logBankLinkError({
        events: [
          {
            payload: error,
            event_type: 'PLAID_LINK_ERROR'
          }
        ]
      });
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('ACP Plaid: Could not log bank link error', ex);
    }
  }

  private async getPlaidConfig(): Promise<PlaidLinkConfigResponse> {
    return this.acpPlaidPluginClient.getPlaidLinkConfig({
      redirectUri: await this.getRedirectUri()
    });
  }

  private async getPlaidUpdateModeLinkConfig(
    bankLinkId: string
  ): Promise<PlaidLinkConfigResponse> {
    return this.acpPlaidPluginClient.getPlaidUpdateModeLinkConfig({
      bankLinkId,
      redirectUri: await this.getRedirectUri()
    });
  }

  private getLinkConfigFromResponse(
    response: PlaidLinkConfigResponse,
    eventHandlers: PlaidEventHandlers
  ): PlaidLinkConfig {
    let receivedRedirectUri = null;
    if (this.hasOAuth()) {
      receivedRedirectUri = this.$location.url();
    } else {
      this.setLinkToken(response.link_token);
    }
    return {
      receivedRedirectUri,
      token: response.link_token,
      ...this.extendEventHandler(eventHandlers)
    };
  }

  private loadScriptFromResponse(response: PlaidLinkConfigResponse) {
    if (this.$window.Plaid) {
      this.setPlaidLoading(false);
      return;
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = response.script_url;
    script.onload = () => {
      this.plaidInstance = Plaid.create(this.linkConfig);
      this.setPlaidLoading(false);
      if (this.hasOAuth()) {
        this.openPlaid();
      }
    };
    document.body.appendChild(script);
  }

  async initPlaid(
    eventHandlers: PlaidEventHandlers,
    reauthBankLinkId?: string
  ) {
    // store reauth id in storage
    if (!this.isOAuthUrl()) {
      reauthBankLinkId
        ? this.setReauthBankLinkId(reauthBankLinkId)
        : this.removeReauthBankLinkId();
    }
    // get config from plaid
    const response = reauthBankLinkId
      ? await this.getPlaidUpdateModeLinkConfig(reauthBankLinkId)
      : await this.getPlaidConfig();

    // if url has oauth_state_id and has link token in session storage
    if (this.hasOAuth()) {
      response.link_token = this.getLinkToken();
    }
    // get link information from response
    this.linkConfig = this.getLinkConfigFromResponse(response, eventHandlers);
    // load script
    this.loadScriptFromResponse(response);
  }

  async openPlaidMobile(
    eventHandlers: PlaidEventHandlers,
    reauthBankLinkId?: string
  ) {
    const response = reauthBankLinkId
      ? await this.getPlaidUpdateModeLinkConfig(reauthBankLinkId)
      : await this.getPlaidConfig();

    // open plaid plugin
    this.$window.plugins.PlaidPlugin?.openPlaid(
      response.link_token,
      (data: PlaidPluginSuccess) =>
        mobilePlaidSuccess(data, this.extendEventHandler(eventHandlers)),
      mobilePlaidError
    );
  }

  openPlaid() {
    this.plaidInstance = Plaid.create(this.linkConfig);
    this.plaidInstance.open();
  }

  // from the time Plaid forces OAuth and till we integrate plaid sdk - as a workaround we navigate users to external browser for linking
  openBTBrowser(redirectTo: string, callback: () => void) {
    const token = window.btoa(
      JSON.stringify({
        redirect_to: redirectTo,
        access_token: this.webapiResource.getToken()
      })
    );

    const url = window.location.origin + '/account/login/authorize#' + token;
    this.$window.cordova.InAppBrowser.open(url, '_system', 'noopener');
    callback();
  }

  destroyPlaid() {
    if (this.plaidInstance) {
      this.plaidInstance.destroy();
    }
  }
}