import { PlaidEventHandlers, PlaidPluginSuccess } from './types';

export const mobilePlaidSuccess = (
  data: PlaidPluginSuccess,
  eventHandler: PlaidEventHandlers
) => {
  let metadata = null;

  try {
    metadata = JSON.parse(data.resultData).metadata;
  } catch (error) {
    console.log('ACP Plaid: Error while parsing resultData', error);
  }

  if (!metadata) {
    return;
  }

  switch (data.eventType) {
    case 'success':
      // metadata is string in success event
      const metaData = JSON.parse(metadata);
      eventHandler.onSuccess(metaData.public_token, metaData);
      break;
    case 'exit':
      eventHandler.onExit(metadata.error, metadata);
      break;
    case 'event':
      eventHandler.onEvent(metadata.eventName, metadata);
      break;
    default:
      console.log('ACP Plaid: No event matched', data.eventType);
  }
};

export const mobilePlaidError = (error: any) => {
  console.log('ACP Plaid: ERROR', error);
};
